
@tailwind base;
@tailwind components;
@tailwind utilities;


#banner{
    padding-top: 10rem;
    text-align: center;
    height: 90vh;
    background-color: black;
   
    background-image: url('./images/banner-img.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

/* #banner h1 {
    color: darkred;
    
} */

#banner-text{
position: absolute;
align-self: start;
text-align: center;
background-color: black;
width: 100%;
opacity: 0.8;
height: 90vh;

}

#banner-text{
    padding-top: 10rem;
}

#projects{
    background-color: rgb(58, 107, 140);
    min-height: 60vh;
    
}

#projects h3{
    color: rgb(235, 235, 235);
    padding-bottom: 1rem;
    padding-top: 5rem;
}

#news{
    background-color: chocolate;
}

#contact-us{
    padding-top: 8rem;
}

h1 {
    text-align: center;
}

h3{
    text-align: center;
}

#where-we-work{
    padding-top: 8rem;
    text-align: center;
}